import { convertWiuzQuantity, priceParser } from 'utils';
import { UpdateWiuzProductRow } from 'types/product';
import { INormalizedList } from 'types';
import { useAppDispatch, useAppSelector } from 'store/store';
import { SavedCartStatusEnum } from 'store/savedCart/types';
import { clearProductError, saveSavedCart, updateSavedCart } from 'store/savedCart';
import {
  clearWiuzProducts,
  selectNormalizedWiuzProductsList,
  selectWiuzImportId,
  updateWiuzProducts,
} from 'store/products';
import { sendMessage } from 'store/messages';
import { selectDeliveryPricesSummary } from 'store/delivery';
import { selectIsGroup, selectSelectedCustomerObject, selectSelectedGroupCustomers } from 'store/customers';
import { clearCartRequestsState } from 'store/cartRequests';
import {
  clearCartState,
  orderValidation,
  selectAllCartTaxes,
  selectCartProducts,
  selectCartStatus,
  selectIsLoading,
  selectIsSavedCart,
} from 'store/cart';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FC, useCallback, useMemo } from 'react';
import { useStepper } from 'hooks';
import { useToken } from 'cookies';
import env from 'config';
import { Summary, TextKeyValue } from 'components/shared';
import { Spin } from 'antd';
import { isFulfilled } from '@reduxjs/toolkit';
import { getValidatedPath } from 'constants/routes';
import styles from './ValidationSummary.module.scss';

const ValidationSummary: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { token } = useToken();
  const {
    totalProductsPrice,
    totalProductsPriceWithFeesAndTaxes,
    totalProductsPriceWithTaxes,
    shippingCategoriesPrices,
  } = useSelector(selectDeliveryPricesSummary);
  const { rpdSum, cvoSum } = useSelector(selectAllCartTaxes);
  const isGroup = useSelector(selectIsGroup);
  const isLoading = useSelector(selectIsLoading);
  const selectedCustomerObject = useSelector(selectSelectedCustomerObject);
  const selectedGroupCustomers = useSelector(selectSelectedGroupCustomers);
  const cartProducts = useAppSelector(selectCartProducts);
  const wiuzImportId = useAppSelector(selectWiuzImportId);
  const selectedWiuzProducts = useAppSelector(selectNormalizedWiuzProductsList);
  const { currentStep } = useStepper();
  const isSavedCart = useSelector(selectIsSavedCart);
  const savedCartStatus = useSelector(selectCartStatus);

  const { t } = useTranslation();

  const blockedActionStatus = useMemo(() => {
    if (!isSavedCart || !savedCartStatus) return false;
    if ([SavedCartStatusEnum.CONVERTED, SavedCartStatusEnum.REFUSED].includes(savedCartStatus)) return true;
  }, [savedCartStatus, isSavedCart]);

  const createWiuzProductsUpdate = useCallback((): Array<UpdateWiuzProductRow> => {
    return Object.values(
      cartProducts.reduce<INormalizedList<UpdateWiuzProductRow>>((acc, product) => {
        const {
          quantity: quantityInCart,
          quantityIncrement = 1,
          unit: { singular: unitSingular },
          wiuz,
        } = product;
        if (wiuz) {
          const { id, unitRelations, unit } = wiuz;

          const qtyInCart = convertWiuzQuantity(quantityInCart * quantityIncrement, {
            unitRelations,
            unit,
            unitSingular,
          });

          if (Object.prototype.hasOwnProperty.call(acc, id)) {
            acc[id].quantity += qtyInCart;
          } else {
            acc[id] = {
              id,
              quantity: selectedWiuzProducts[id].quantity + qtyInCart,
            };
          }
        }
        return acc;
      }, {}),
    );
  }, [cartProducts, selectedWiuzProducts]);

  const saveAndGetAbandonnedCartId = async (): Promise<string | undefined> => {
    const stepNumber = currentStep?.index || 4;
    const response = await dispatch(saveSavedCart({ stepNumber }));

    if (isFulfilled(response)) {
      return response.payload.cart.id;
    }
  };

  const dispatchOrderValidationFulfilled = () => {
    dispatch(clearCartState());
    dispatch(clearCartRequestsState());
    dispatch(sendMessage({ message: t('common.orderCompletedSuccessfully'), type: 'success' }));
    navigate(getValidatedPath('order'));
  };

  const handleValidateOrder = async () => {
    const userOrGroupId = isGroup
      ? selectedGroupCustomers.find((customer) => customer.leaderId > 0)?.leaderId || 0
      : selectedCustomerObject?.userId ?? 0;

    const savedCartId = await saveAndGetAbandonnedCartId();
    const orderValidationResponse = await dispatch(
      orderValidation({ userId: userOrGroupId, token, groupOrder: isGroup }),
    );

    dispatch(clearProductError());

    if (isFulfilled(orderValidationResponse)) {
      if (savedCartId) {
        const masterOrderId = orderValidationResponse.payload.id;

        dispatch(
          updateSavedCart({
            id: savedCartId,
            data: {
              status: SavedCartStatusEnum.CONVERTED,
              masterOrderId,
              convertedAt: new Date().toISOString(),
            },
          }),
        );
      }

      if (wiuzImportId) {
        if (isFulfilled(await dispatch(updateWiuzProducts(createWiuzProductsUpdate())))) {
          dispatch(clearWiuzProducts());
        }
      }
      dispatchOrderValidationFulfilled();
    }
  };

  return (
    <>
      <Summary
        onClick={handleValidateOrder}
        buttonText={t('common.validateOrder')}
        disabled={!env('enableOrderValidation') || blockedActionStatus}
        orderValidation={true}
        className={isGroup ? styles.summary : ''}
      >
        {isLoading ? <Spin className={styles.loading} size="large" /> : null}
        <TextKeyValue
          size="large"
          label={`${t('common.totalProductHt')}`}
          value={priceParser(totalProductsPrice)}
          className={styles.price}
        />
        <TextKeyValue
          size="large"
          label={`${t('common.totalProductTtc')}`}
          value={priceParser(totalProductsPriceWithTaxes)}
          className={styles.price}
        />
        <TextKeyValue label={`${t('common.rpdTax')}`} value={priceParser(rpdSum)} className={styles.prices} />
        <TextKeyValue label={`${t('common.cvoTax')}`} value={priceParser(cvoSum)} className={styles.prices} />

        {shippingCategoriesPrices.map(
          (item) =>
            item.hasItems &&
            !!item.price && <TextKeyValue key={item.label} label={item.label} value={priceParser(item.price)} />,
        )}

        <div className={styles.resultsBlock}>
          <TextKeyValue
            size="large"
            label={`${t('common.totalPrice')}`}
            value={priceParser(totalProductsPriceWithFeesAndTaxes)}
            className={`${styles.price} ${styles.price_spaced}`}
          />
        </div>
      </Summary>
    </>
  );
};

export default ValidationSummary;
