import { ICartRequestDetail } from 'types/cartRequest';

import { RootState } from 'store/rootReducer';

export const selectCartRequestDetail = (state: RootState): ICartRequestDetail | null =>
  state.cartRequests.cartRequestDetail;

export const selectCartRequestId = (state: RootState): number | null => {
  if (state.settings.PRODUCT_PAGE === 'customer-quotation' && state.cartRequests.cartRequestDetail?.id) {
    return state.cartRequests.cartRequestDetail?.id;
  }
  return null;
};
