import { useAppSelector } from 'store/store';
import { ICartProductItem } from 'store/cart/types';
import { selectCartProducts } from 'store/cart';
import { FC, useCallback, useMemo } from 'react';
import { round } from 'lodash';
import { config } from 'config';
import { Text } from 'components/shared';
import { CheckSquareOutlined, CloseSquareOutlined } from '@ant-design/icons';
import styles from './styles.module.scss';

interface StockProps {
  stock: number;
  offerId: number | undefined;
  unit?: { singular: string; plural: string };
  conditioning?: string;
  quantityIncrement?: number;
}

const Stock: FC<StockProps> = ({ stock = 0, offerId, unit, conditioning, quantityIncrement = 1 }: StockProps) => {
  const cartProducts = useAppSelector(selectCartProducts);
  const { enableStockColumn } = config;

  const actualStock = useMemo((): number => {
    if (!offerId) {
      return stock;
    }

    const productInCart = cartProducts.find((item: ICartProductItem) => item.offerId === offerId);
    if (!productInCart) {
      return stock;
    }
    return stock - productInCart.quantity * productInCart.quantityIncrement;
  }, [cartProducts, stock, offerId]);

  const StockQuantity = useCallback(() => {
    if (!enableStockColumn) {
      return null;
    }
    return (
      <Text type="subtitle-thin" className={styles.text}>
        {round(actualStock, 3).toLocaleString('fr-FR')} {unit && (actualStock > 1 ? unit.plural : unit.singular)}
        {conditioning && (
          <p>
            {round(actualStock / quantityIncrement, 2).toLocaleString('fr-FR')} {conditioning}
          </p>
        )}
      </Text>
    );
  }, [actualStock, enableStockColumn, unit]);

  return (
    <div className={styles.content}>
      {actualStock > 0 ? (
        <CheckSquareOutlined className={styles.available} />
      ) : (
        <CloseSquareOutlined className={styles.unavailable} />
      )}
      <StockQuantity />
    </div>
  );
};
export default Stock;
