export const cart_en = {
  cartAttachmentUploadSuccess: '({{count}}) files has been uploaded.',
  noShippingMethods: 'No shipping methods',
  qtyEditableCheckboxLabel: 'Quantity editable',
  qtyEditableCheckboxTooltip:
    'The customer will be able to edit the quantity before validating (within the limit of stock).',
  removableCheckboxLabel: 'Removable',
  removableCheckboxTooltip: 'The customer will be able to delete the item before validating the quote.',
  replaceOffer: 'Replace offer',
  replace: 'Replace',
  articleInclusive: 'The customer will not see the item if it is excluded but will remain present in the quote',
  uploadMore: 'Upload more files',
  cartAttachments: 'Attachments',
  discountTooltip: 'Apply discount',
  commentTooltip: 'Add a comment to the product',
  warehouseStockTooltip: 'Warehouse stock',
  deleteTooltip: 'Delete product',
};
